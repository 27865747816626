
.navbar
{
  overflow-x: hidden;

}

.img-container {
  width: 100%;
  overflow: hidden;
}

.img-container img {
  width: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover {
  color:#e8563b !important;
}

/*      layer animation          */

.layers-container {
  display: block;
  margin-top: 50px;
  position: relative;
}

.layers-container img {
  position: absolute;
  width: 100%;
  height: auto;
  top: 0;
  left: 0;
  text-align: center;
}

.section-black {
  background-color: #333;
}

#layerHover {
  top: 30px;
}

#layerImage {
  top: 50px;
}

#layerBody {
  top: 75px;
}

.onclick {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 20001;
}


.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes bounceInDown {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes bounceInDown {
  0%,
  60%,
  75%,
  90%,
  100% {
    -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, -3000px, 0);
    transform: translate3d(0, -3000px, 0);
  }
  60% {
    opacity: 1;
    -webkit-transform: translate3d(0, 25px, 0);
    transform: translate3d(0, 25px, 0);
  }
  75% {
    -webkit-transform: translate3d(0, -10px, 0);
    transform: translate3d(0, -10px, 0);
  }
  90% {
    -webkit-transform: translate3d(0, 5px, 0);
    transform: translate3d(0, 5px, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}

.bounceInDown {
  -webkit-animation-name: bounceInDown;
  animation-name: bounceInDown;
}


.navbar-header {
  min-width: 135px;
}
.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
}



.media-post .form {
  width: 100%;
}

.portfolio-page #demoPay .title:nth-of-type(2) {
  margin-top: 0;
}

.portfolio-page .main {
  margin-top: -96px;
}

.portfolio-page .card img {
  width: 100%;
}

.portfolio-page .title {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.portfolio-page .title h2 {
  font-size: 2.5em;
}

.portfolio-page .section-light-brown {
  color: #66615b;
}

.portfolio-page .section-dark-filter {
  position: relative;
}

.portfolio-page .section-dark-filter:after {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  top: 0;
  left: 0;
  background: black;
  opacity: .66;
  content: "";
}

.portfolio-page .section-header {
  position: relative;
}

.portfolio-page .section-summary {
  margin-top: -120px;
  padding-top: 165px;
}

.skills-header, .skill-category-header {
  color: #e8563b;
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
}
.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 20px;
}
.skill-item {
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 8px;
  padding: 5px 15px;
  cursor: default;
  &:hover {
    background-color: #e8563b;
    font-weight: 400;
    color: white;
  }
}

.skill-image {
  width: 180%;
  height: auto;
  padding-top: 6rem;

}
.skill-item, .skill-item a {
  color: black;
  text-decoration: none;

}
.certification-item a {
  background-color: white;
  color: black;
  border: 1px solid black;
  border-radius: 8px;
  padding: 5px 15px;
  cursor: pointer;
  &:hover {
    background-color: #e8563b;
    font-weight: 400;
    color: white;
  }
}

@media (max-width: 768px) {
  .skill-image {
    width: 100%;
    padding-top: 5rem;
    padding-left: 2rem;
  }
}
.portfolio-page .components-container {
  margin-top: 80px;
}

.portfolio-page .components-container .card {
  cursor: pointer;
  background: transparent;
  box-shadow: none;
  margin-bottom: 120px;
  padding: 10px;
}

.portfolio-page .components-container .card.no-margin {
  margin-bottom: 0;
}

.portfolio-page .components-container .col-with-animation {
  padding-top: 200px;
}

.portfolio-page .section-icons {
  padding: 180px 0;
}

.portfolio-page .section-icons [class*="col-"]:nth-child(2) {
  padding: 0 70px;
}

.portfolio-page .section-icons .icons-nucleo .nc-icon {
  position: absolute;
  opacity: 0;
  transform: translate3D(0px, 50px, 0px);
  -webkit-transform: translate3D(0px, 50px, 0px);
  -webkit-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
  -moz-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
  -o-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
  -ms-transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
  transition: all 0.6s cubic-bezier(0.34, 2, 0.7, 1) 0s;
}

.portfolio-page .section-icons .icons-nucleo .first-left-icon {
  top: -25%;
  left: 10%;
  font-size: 65px;
}

.portfolio-page .section-icons .icons-nucleo .second-left-icon {
  top: 0%;
  left: 45%;
  font-size: 35px;
}

.portfolio-page .section-icons .icons-nucleo .third-left-icon {
  top: 30%;
  left: 65%;
  font-size: 25px;
}

.portfolio-page .section-icons .icons-nucleo .fourth-left-icon {
  top: 70%;
  left: 10%;
  font-size: 50px;
}

.portfolio-page .section-icons .icons-nucleo .fifth-left-icon {
  top: 45%;
  left: 30%;
  font-size: 40px;
}

.portfolio-page .section-icons .icons-nucleo .sixth-left-icon {
  top: 120%;
  left: 35%;
  font-size: 40px;
}

.portfolio-page .section-icons .icons-nucleo .seventh-left-icon {
  top: 35%;
  left: 85%;
  font-size: 45px;
}

.portfolio-page .section-icons .icons-nucleo .eighth-left-icon {
  top: 90%;
  left: 55%;
  font-size: 30px;
}

.portfolio-page .section-icons .icons-nucleo .ninth-left-icon {
  top: 40%;
  left: 5%;
  font-size: 40px;
}

.portfolio-page .section-icons .icons-nucleo .tenth-left-icon {
  top: 75%;
  left: 80%;
  font-size: 35px;
}

.portfolio-page .section-icons .icons-nucleo .first-right-icon {
  top: -25%;
  right: 10%;
  font-size: 65px;
}

.portfolio-page .section-icons .icons-nucleo .second-right-icon {
  top: 0%;
  right: 45%;
  font-size: 35px;
}

.portfolio-page .section-icons .icons-nucleo .third-right-icon {
  top: 30%;
  right: 65%;
  font-size: 25px;
}

.portfolio-page .section-icons .icons-nucleo .fourth-right-icon {
  top: 70%;
  right: 10%;
  font-size: 50px;
}

.portfolio-page .section-icons .icons-nucleo .fifth-right-icon {
  top: 45%;
  right: 30%;
  font-size: 40px;
}

.portfolio-page .section-icons .icons-nucleo .sixth-right-icon {
  top: 120%;
  right: 35%;
  font-size: 35px;
}

.portfolio-page .section-icons .icons-nucleo .seventh-right-icon {
  top: 35%;
  right: 88%;
  font-size: 30px;
}

.portfolio-page .section-icons .icons-nucleo .eighth-right-icon {
  top: 90%;
  right: 55%;
  font-size: 35px;
}

.portfolio-page .section-icons .icons-nucleo .ninth-right-icon {
  top: 40%;
  right: 5%;
  font-size: 40px;
}

.portfolio-page .section-icons .icons-nucleo .tenth-right-icon {
  top: 75%;
  right: 80%;
  font-size: 35px;
}

.portfolio-page .section-overview .card .card-icon i {
  border: none;
}

.portfolio-page h3.title {
  font-size: 2.1em;
}

.portfolio-page h5 {
  font-size: 1.3em;
}

.portfolio-page .components-container .details-text,
.portfolio-page .section-examples .details-text {
  color: #66615b;
  font-size: 1.1em;
  font-weight: bold;
  line-height: 1.15;
  overflow: hidden;
  padding-top: 3px;
  text-align: center;
  text-overflow: ellipsis;
  margin-bottom: 25px;
}

.portfolio-page .section-components .title {
  margin-top: 60px;
  margin-bottom: 5px;
}

.portfolio-page .section-components .description {
  margin-top: 35px;
  color: #fff;
}

.portfolio-page .section-components .image-container {
  height: 560px;
  position: relative;
}

.portfolio-page .section-components .image-container .components-anime {
  width: auto;
  left: 250px;
  top: 0;
  height: 100%;
  position: absolute;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
  
}

.components-anime:hover {
  animation: wiggle 0.5s ease-in-out infinite;
}


@media (max-width: 968px) {
  .portfolio-page .section-components .image-container {
    display: none; 
  }

}

@media (max-width: 1200px) {
  .portfolio-page .section-components .image-container {
    left: -210px;
  }

  
}
@keyframes wiggle {
  0% { transform: rotate(0deg); }
  25% { transform: rotate(-3deg); }
  50% { transform: rotate(3deg); }
  75% { transform: rotate(-3deg); }
  100% { transform: rotate(3deg); }
}

.portfolio-page .section-components .image-container .table-img,
.portfolio-page .section-components .image-container .share-btn-img,
.portfolio-page .section-components .image-container .coloured-card-btn-img,
.portfolio-page .section-components .image-container .coloured-card-img,
.portfolio-page .section-components .image-container .social-img,
.portfolio-page .section-components .image-container .pin-btn-img {
  position: absolute;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.portfolio-page .section-components .image-container .table-img {
  left: 65%;
  top: 22%;
  height: 175px;
  border-radius: 6px;
}

.portfolio-page .section-components .image-container .share-btn-img {
  left: 70%;
  top: 67%;
  height: 20px;
  border-radius: 10px;
}

.portfolio-page .section-components .image-container .coloured-card-btn-img {
  left: 35%;
  top: 2%;
  height: 125px;
  border-radius: 6px;
}

.portfolio-page .section-components .image-container .coloured-card-img {
  left: -3%;
  top: 40%;
  height: 90px;
  border-radius: 6px;
}

.portfolio-page .section-components .image-container .social-img {
  left: -4%;
  top: 68%;
  height: 40px;
}

.portfolio-page .section-components .image-container .pin-btn-img {
  left: 3%;
  top: 7%;
  height: 25px;
  border-radius: 10px;
}

.portfolio-page .section-sections {
  padding-bottom: 0;
}

.portfolio-page .section-sections .col-md-3 {
  padding: 7.5px;
}

.portfolio-page .section-sections img {
  width: 100%;
  border-radius: 3px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
}

.portfolio-page .section-sections img:hover {
  -webkit-transform: scale(1.02);
  -moz-transform: scale(1.02);
  -o-transform: scale(1.02);
  -ms-transform: scale(1.02);
  transform: scale(1.02);
}

.portfolio-page .section-free-demo {
  padding: 180px 0;
}

.portfolio-page .section-free-demo .icon-github {
  font-size: 400px;
  color: rgba(119, 119, 119, 0.2);
  position: absolute;
  top: -170px;
  left: 60px;
}

.portfolio-page .section-free-demo iframe {
  top: 6px;
  display: inline-block;
  position: relative;
  margin-left: 10px;
}

.portfolio-page .section-free-demo .card-pricing {
  margin-top: 0px;
}

.portfolio-page .section-free-demo h2.title {
  margin-top: 15px;
}

.portfolio-page .section-cards {
  padding: 50px 0;
  min-height: 790px;
}

.portfolio-page .section-cards .codrops-header {
  margin-left: 10%;
  margin-top: 7%;
}

.portfolio-page .section-cards .image-container {
  max-width: 1040px;
  margin-top: -140px;
  position: relative;
  height: 660px;
}

.portfolio-page .section-cards .image-container img {
  max-width: 1040px;
  width: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
}

.portfolio-page .section-cards .title {
  margin-bottom: 5px;
  margin-top: 5px;
}

.portfolio-page .section-cards .description {
  margin-top: 35px;
  max-width: 270px;
  color: #000 !important;
}

.portfolio-page .section-content .section-description {
  margin-top: 100px;
}

.portfolio-page .section-content .section-description .title {
  margin-bottom: 5px;
}

.portfolio-page .section-content .section-description .description {
  margin-top: 35px;

}

.portfolio-page .section-content .image-container {
  max-width: 900px;
  position: relative;
}

.portfolio-page .section-content .image-container .img {
  width: 75%;
  transition: transform .6s .9s;
  z-index: 2;
}

.portfolio-page .section-content .image-container .area-img,
.portfolio-page .section-content .image-container .info-img {
  position: absolute;
  border-radius: 8px;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  opacity: 0;
  transform: translate3D(0px, 100px, 0px);
  -webkit-transform: translate3D(0px, 100px, 0px);
}

.portfolio-page .section-content .image-container .area-img {
  top: 9%;
  left: 45%;
  height: 350px;
  width: 280px;
  z-index: 2;
  -webkit-transition: all 1s ease-out 0s;
  -moz-transition: all 1s ease-out 0s;
  -o-transition: all 1s ease-out 0s;
  -ms-transition: all 1s ease-out 0s;
  transition: all 1s ease-out 0s;
}

.portfolio-page .section-content .image-container .info-img {
  top: 40%;
  left: -25%;
  height: 350px;
  width: 280px;
  -webkit-transition: all 1s ease-out 0s;
  -moz-transition: all 1s ease-out 0s;
  -o-transition: all 1s ease-out 0s;
  -ms-transition: all 1s ease-out 0s;
  transition: all 1s ease-out 0s;
}

.portfolio-page .animated {
  transform: translate3D(0px, 0px, 0px) !important;
  -webkit-transform: translate3D(0px, 0px, 0px) !important;
  opacity: 1 !important;
}

.portfolio-page .section-examples .card {
  background: transparent;
  margin-bottom: 40px;
  display: inline-block;
  box-shadow: 0px 15px 18px -10px rgba(22, 22, 22, 0.5);
  -webkit-transform: translate3d(0, 0px, 0);
  -moz-transform: translate3d(0, 0px, 0);
  -o-transform: translate3d(0, 0px, 0);
  -ms-transform: translate3d(0, 0px, 0);
  transform: translate3d(0, 0px, 0);
  -webkit-transition: all 300ms cubic-bezier(0.34, 2, 0.6, 1);
  -moz-transition: all 300ms cubic-bezier(0.34, 2, 0.6, 1);
  -o-transition: all 300ms cubic-bezier(0.34, 2, 0.6, 1);
  -ms-transition: all 300ms cubic-bezier(0.34, 2, 0.6, 1);
  transition: all 300ms cubic-bezier(0.34, 2, 0.6, 1);
}

.portfolio-page .section-examples .card:hover {
  -webkit-transform: translate3d(0, -12px, 0);
  -moz-transform: translate3d(0, -12px, 0);
  -o-transform: translate3d(0, -12px, 0);
  -ms-transform: translate3d(0, -12px, 0);
  transform: translate3d(0, -12px, 0);
}

.portfolio-page .section-examples .card img {
  border-radius: 12px !important;
}

.portfolio-page .section-examples {
  padding-top: 100px;
  margin-top: -30px;
  padding-bottom: 130px;
}

.portfolio-page .section-sharing {
  padding-bottom: 130px;
}

.portfolio-page .section-sharing span {
  margin-top: 30px;
  display: block;
}

.portfolio-page .section-sharing .fa-pay {
  font-size: 30px;
  display: inline-block;
  margin: 5px 1px 0;
  width: auto;
  color: #FFFFFF;
}

.portfolio-page .section-sharing .text-muted {
  opacity: .8;
}

.portfolio-page .section-sharing .card-pricing.card-margin {
  margin-top: 45px;
}

.portfolio-page .section-responsive .phone-container {
  max-width: 800px;
  margin-left: -15px;
  max-height: 390px;
}

.portfolio-page .section-responsive .phone-container img {
  width: 100%;
  margin-top: -150px;
}

.portfolio-page .section-responsive .info {
  margin-bottom: 0px;
}

.portfolio-page .section-responsive .info h4 {
  font-size: 1.4em;
}

.portfolio-page .title-brand {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  color: #FFFFFF;
  display: block;
}

.portfolio-page .title-brand .type {
  position: absolute;
  font-size: 20px;
  background: #132026;
  padding: 6px 10px;
  border-radius: 4px;
  top: 0;
  font-weight: 600;
  margin-top: 10px;
  right: -15px;
}

.portfolio-page .portfolio-title {
  font-size: 7em;
  font-weight: 700;
  margin: 0;
  font-family: 'CustomFont', sans-serif;
  
  /* Gradient Background */
  background: linear-gradient(to bottom, #eb5e28, black);

  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.btn-default {
  background-color: #ffffff; /* Default background color */
  color: #000000; /* Default text color */
  border: 2px solid #eb5e28; /* Border matching the hover color */
  transition: all 0.3s ease-in-out;
}

.btn-default:hover {
  background-color: #eb5e28 !important;
  color: #ffffff !important; /* Change text color on hover */
  border-color: #eb5e28 !important;
}





.portfolio-page .portfolio-subtitle {
  font-size: 1.7em;
  color: #FFFFFF;
}

.portfolio-page .category-absolute {
  position: absolute;
  text-align: center;
  top: 100vh;
  margin-top: -60px;
  padding: 0 15px;
  width: 100%;
  color: rgba(255, 255, 255, 0.5);
}

.portfolio-page .category-absolute .joshuaheaderfootter {
  max-width: 140px;
  top: -3px;
  left: 3px;
  position: relative;
}

.portfolio-page .fog-low {
  position: absolute;
  left: 0;
  bottom: 0;
  margin-left: -35%;
  margin-bottom: -50px;
  width: 110%;
  opacity: .85;
}

.portfolio-page .fog-low img {
  width: 100%;
}

.portfolio-page .fog-low.right {
  margin-left: 30%;
  opacity: 1;
}


.pseudo-scroller {
  pointer-events: none;
}


.isolayer {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
}

.js .grid,
.js .grid__item,
.js .grid__link {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}

.grid {
  position: relative;
  margin: 0 auto;
  padding: 0;
  list-style: none;
}

.grid__item {
  width: 300px;
}

.js .grid__item {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.grid__link {
  position: relative;
  z-index: 1;
  display: block;
}

.grid__img {
  display: block;
  max-width: 100%;
}

.grid__title {
  font-size: 0.65em;
  font-weight: 600;
  position: absolute;
  z-index: -1;
  bottom: 0;
  width: 100%;
  text-align: center;
  letter-spacing: 2px;
  text-transform: uppercase;
  opacity: 0;
  color: #fff;
  -webkit-transform: translate3d(0, -20px, 0);
  transform: translate3d(0, -20px, 0);
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  transition: transform 0.3s, opacity 0.3s;
}

.grid__item:hover .grid__title {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.layer {
  position: relative;
  display: block;
}

.layer:not(:first-child) {
  position: absolute;
  top: 0;
  left: 0;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 70px;
}
.scroll-to-top {
  position: fixed;
  bottom: 40px;
  right: 40px;
  cursor: pointer;
  z-index: 1000; 
  background-color: #e8563b;
  color: #fff;
  border: none;
  padding: 10px 15px;
  border-radius: 50%;
}

@media (max-width: 968px) {
  .navbar-smaller-buttons .btn-neutral {
      font-size: 12px;
      padding: 5px 10px;
      color: black;
  }

  .fixed-top {
    position: absolute;  
    top: 0;          
    left: 0;       
    right: 0;       
    background-color: transparent !important;
    z-index: 1030;
  }

  .portfolio-title {
    font-size: 50px !important;
  }

  .portfolio-subtitle {
    font-size: 18px; 
  }

  .text-center h5 {
    font-size: 14px; 
  }
  
  .skill-image {
    display: none;
  }
  .col-md-6{
    max-width: 100%;
  }

}




@media (min-width: 3068px) and (max-width: 5000px) {
  .portfolio-page .section-components .image-container .components-anime {
    width: auto;
    left: 580px;
    top: 0;
    height: 100%;
    position: absolute;
    transition: transform 0.5s ease-in-out;
    cursor: pointer;
  }

  /* Center the About Me Title */
  .about-me {
    text-align: left !important;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }

  /* Center the About Me Description */
  .about-description  {
    text-align: center;
    max-width: 60%; 
    margin: 0 auto;
  }

  .about-me-container
  {
    margin-left: -40%;
  }

  .components-anime:hover {
    animation: wiggle 0.5s ease-in-out infinite;
  }
  
 
  }


.scroll-to-top:hover {
  opacity: 1;
}






